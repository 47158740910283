import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { center, device } from '../utils'

import styled from 'react-emotion'

const FeedContainer = styled('div')`
  color: white;
  ${center};

  h3 {
    text-align: center;
    font-size: 1.6em;
    text-transform: uppercase;
  }

  a {
    color: ${p => p.theme.highlight};
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  p {
    margin-bottom: 0;
  }

  @media ${device.tablet} {
    h3 {
      text-align: inherit;
    }
  }
`

const ImagesContainer = styled('div')`
  display: grid;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 4em;
  grid-gap: 2em;
  grid-template-columns: 200px;
  grid-auto-rows: 200px;
  justify-content: center;

  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 200px);
    grid-auto-rows: 200px;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(4, 150px);
    grid-auto-rows: 150px;
    align-content: space-around;
    justify-content: space-between;
  }

  @media ${device.laptopL} {
    grid-template-columns: repeat(4, 200px);
    grid-auto-rows: 200px;
    align-content: space-around;
    justify-content: space-between;
  }
`

const Image = styled('img')`
  display: block;
  background: blue;
  width: 100%;
  height: 100%;
`

const ImageLink = styled('a')`
  transition: 0.05s ease-in-out;
  transition-property: transform;

  &:hover {
    transform: scale(1.1);
  }
`

export const Feed = () => (
  <StaticQuery
    query={graphql`
      query FeedQuery {
        site {
          siteMetadata {
            instagramUrl
          }
        }
        images: allFile(filter: { relativePath: { glob: "feed/*.jpg" } }) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 512) {
                  srcSet
                }
              }
            }
          }
        }
      }
    `}
    render={({ site, images }) => (
      <FeedContainer>
        <h3>@Slapstick_App</h3>
        <p>
          Follow us on{' '}
          <a
            href={site.siteMetadata.instagramUrl}
            aria-label="Slapstick Instagram"
          >
            Instagram
          </a>{' '}
          for new sticker announcements and other slap-spiration.
        </p>
        <ImagesContainer>
          {images.edges.map(edge => edge.node).map(node => {
            return (
              <ImageLink
                href={site.siteMetadata.instagramUrl}
                key={node.id}
                aria-label="Slapstick Instagram"
              >
                <Image srcSet={node.childImageSharp.fluid.srcSet} />
              </ImageLink>
            )
          })}
        </ImagesContainer>
      </FeedContainer>
    )}
  />
)
