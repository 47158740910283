import React from 'react'
import styled from 'react-emotion'
import { device } from '../utils'
import { Text } from './text'
import { ExternalLink } from './'
import { StaticQuery, graphql } from 'gatsby'
import { SlapstickIcon, BuckLogo } from './'

const BrandingContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 110px;
  flex-direction: column;
  justify-content: space-between;
  order: 1;
  margin-top: 2em;

  @media ${device.tablet} {
    width: 150px;
    flex-direction: row;
    order: inherit;
    margin-top: inherit;
  }
`

const BuiltBy = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media ${device.tablet} {
    order: inherit;
  }
`

const BuiltByText = styled('div')`
  display: flex;
  font-size: 0.8em;
  font-weight: bold;
  flex-direction: column;
`

export const Branding = () => (
  <StaticQuery
    query={graphql`
      query BrandingQuery {
        site {
          siteMetadata {
            builtByUrl
            downloadUrlIos
            downloadUrlAndroid
          }
        }
      }
    `}
    render={({ site }) => (
      <BrandingContainer>
        <ExternalLink
          href={site.siteMetadata.downloadUrlIos}
          aria-label="Download Slapstick"
        >
          <SlapstickIcon />
        </ExternalLink>
        <BuiltBy>
          <BuiltByText>
            <Text center small uppercase>
              Built By
            </Text>
            <ExternalLink
              href={site.siteMetadata.builtByUrl}
              aria-label="Buck Design website"
            >
              <BuckLogo />
            </ExternalLink>
          </BuiltByText>
        </BuiltBy>
      </BrandingContainer>
    )}
  />
)
