import React from 'react'
import styled from 'react-emotion'
import { device, blacklistProps } from '../utils'
import { Text, Button } from '../components'

import addToMailchimp from 'gatsby-plugin-mailchimp'

const EmailInput = blacklistProps(
  ['flex', 'error'],
  styled('input')`
    position: relative;
    appearance: none;
    border-radius: 0;
    border: 2px solid white;
    padding: 1em;
    font-size: 1em;
    outline: none;
    width: 100%;

    &:active,
    &:focus {
      border: 2px solid black;
    }
  `
)

const EmailInputContainer = styled('div')`
  display: flex;
  transition: 0.2s ease;
  transition-property: width;
`

const EmailSubmissionForm = styled('form')`
  margin-bottom: 3em;
`

const InputMessageContainer = styled('div')`
  align-items: center;
  justify-content: center;
  font-style: italic;
  padding: 0.5em 0;
  height: 3em;
  font-weight: 700;

  @media ${device.tablet} {
    justify-content: initial;
  }

  a {
    color: inherit;
  }
`

const SuccessMessage = styled('p')`
  font-size: 1.2em;
  padding-top: 0;

  margin-bottom: 4em;
`

const DotLoaderSVG = styled('svg')`
  fill: white;

  circle {
    animation: pulse 1s infinite ease-in-out;
  }

  #circle-0 {
    animation-delay: 0;
  }

  #circle-1 {
    animation-delay: 0.25s;
  }

  #circle-2 {
    animation-delay: 0.5s;
  }

  @keyframes pulse {
    0% {
      r: 5;
    }
    50% {
      r: 10;
    }
    100% {
      r: 5;
    }
  }
`

const DotLoader = () => (
  <DotLoaderSVG width="80px" height="30px" viewBox="0 0 80 30">
    <circle id="circle-0" cx="15" cy="15" r="5" />
    <circle id="circle-1" cx="40" cy="15" r="5" />
    <circle id="circle-2" cx="65" cy="15" r="5" />
  </DotLoaderSVG>
)

const Below = styled('div')`
  display: flex;
  font-size: 0.85em;
  margin-top: 1em;
  margin-bottom: 1em;
`

const Checkbox = styled('input')`
  appearance: none;
  flex: 0 0 2.1em;
  border: 2px solid black;
  display: inline-block;
  height: 2.1em;
  margin-right: 0.5em;
  color: transparent;
  transition: 0.2s;
  cursor: pointer;
  outline: none;

  &:hover {
    border: 4px solid black;
  }

  &:checked {
    background: black;
    color: #fff;
  }
`

const CallToAction = styled('p')`
  margin-bottom: 1.5em;
`

export class SignUpForm extends React.Component {
  state = {
    email: '',
    isValid: false,
    isWaiting: false,
    hasError: false,
    isSubmissionSuccess: false,
    message: ``,
    userConsents: false,
  }

  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  onEmailInputChange = async e => {
    const { value } = e.target
    const trimmedValue = value.trim()
    await this.setState({ email: trimmedValue })
    this.validate()
  }

  onUserConsentChange = async e => {
    const { checked } = e.target
    await this.setState({ userConsents: checked })
    this.validate()
  }

  validate = async () => {
    const isValid =
      this.validateEmail(this.state.email) && this.state.userConsents
    await this.setState({ isValid })
  }

  handleSubmit = async e => {
    e.preventDefault()

    const { email } = this.state
    const listFields = {}

    if (this.state.userConsents) {
      listFields.CONSENT = `By checking this box, I agree to let Slapstick use my info to occasionally slap my inbox.`
      listFields.CONSENTON = new Date().toUTCString()
    }

    if (!this.state.isValid) {
      await this.setState({
        hasError: true,
        message: `Sorry, we need an email and your agreement in order to sign you up! You can always unsubscribe if we're boring.`,
      })
      return
    }

    await this.setState({ isWaiting: true })

    const response = await addToMailchimp(email, listFields)

    await this.setState({ isWaiting: false })

    if (response) {
      const { result, msg } = response

      if (result == 'error') {
        await this.setState({ hasError: true, message: msg })
      } else if (result == 'success') {
        await this.setState({
          hasError: false,
          isSubmissionSuccess: true,
          message: msg,
        })
      }
    } else {
      await this.setState({
        hasError: true,
        message: 'Sorry! Something went wrong, please try again later.',
      })
    }
  }

  render() {
    let {
      hasError,
      isValid,
      isWaiting,
      isSubmissionSuccess,
      message,
      email,
      userConsents,
    } = this.state

    if (isSubmissionSuccess) {
      return (
        <SuccessMessage>
          <b>Thanks!</b> You'll receive a confirmation email shortly. Then
          you'll be signed up to get notified about Slapstick!
        </SuccessMessage>
      )
    }

    return (
      <EmailSubmissionForm onSubmit={this.handleSubmit}>
        <CallToAction>
          Want to be the first to know when Slapstick is ready for download?
          Sign up and get slapped.
        </CallToAction>

        <EmailInputContainer>
          <EmailInput
            type="text"
            spellCheck="false"
            placeholder="email address"
            value={email}
            error={hasError}
            onChange={this.onEmailInputChange}
          />
          <Button>
            {!isWaiting && (
              <Text bold uppercase>
                Get notified
              </Text>
            )}
            {isWaiting && <DotLoader />}
          </Button>
        </EmailInputContainer>
        <Below>
          <Checkbox
            id="gdpr"
            type="checkbox"
            value={userConsents}
            error={hasError}
            onChange={this.onUserConsentChange}
          />
          <div>
            <label htmlFor="gdpr">
              By checking this box, I agree to let Slapstick use my info to
              occasionally slap my inbox. <b>(Required)</b>
            </label>
            <InputMessageContainer>
              {hasError && (
                <div dangerouslySetInnerHTML={{ __html: message }} />
              )}
            </InputMessageContainer>
          </div>
        </Below>
      </EmailSubmissionForm>
    )
  }
}
