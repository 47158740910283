import React from 'react'
import styled from 'react-emotion'
import { center } from '../utils'

const DividerContainer = styled('div')`
  ${center};
`

const DividerBorder = styled('div')`
  border-bottom: 2px solid white;
`

export const Divider = () => (
  <DividerContainer>
    <DividerBorder />
  </DividerContainer>
)
