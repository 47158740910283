import React from 'react'
import styled from 'react-emotion'
import { device } from '../utils'
import { Text } from './text'
import { Link, ExternalLink, ContainerFlex, Branding, Divider } from './'
import { StaticQuery, graphql } from 'gatsby'

const FooterContainer = styled(ContainerFlex)`
  position: relative;
  flex-direction: column;
  align-items: center;

  padding: 2.75em 0;

  > * {
    margin-bottom: 1em;
  }

  @media ${device.tablet} {
    margin-top: 0;
    justify-content: space-between;
    flex-direction: row;
  }
`

const LinkContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 3em;
  text-align: center;

  @media ${device.tablet} {
    text-align: inherit;
  }
`

export const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            title
            pressKitUrl
          }
        }
      }
    `}
    render={({ site }) => (
      <FooterContainer>
        <LinkContainer>
          <Link to="/terms">
            <Text small uppercase>
              Terms & Conditions
            </Text>
          </Link>
          <Link to="/privacy">
            <Text small uppercase>
              Privacy Policy
            </Text>
          </Link>
          <ExternalLink
            href={site.siteMetadata.pressKitUrl}
            aria-label="Press Kit Download"
          >
            <Text small uppercase>
              Press Kit
            </Text>
          </ExternalLink>
        </LinkContainer>
        <Branding />
        <Text small uppercase>
          ©BUCK DESIGN, LLC {new Date().getFullYear()}
        </Text>
      </FooterContainer>
    )}
  />
)
