import React from 'react'
import styled from 'react-emotion'

const SlapstickLogoSVG = styled('svg')`
  fill: white;
  height: 2.5em;

  &:hover {
    fill: ${p => p.theme.highlight};
  }
`

export const SlapstickLogo = () => (
  <SlapstickLogoSVG x="0px" y="0px" viewBox="0 0 99.6 57.9">
    <g>
      <path
        d="M33.9,2.5c-1.2-0.7-5.3-3-10.4-2.2c-5.7,0.8-8.5,5-9,7.6c-0.4,2.1-0.5,5.1,3.2,7.5c0.7,0.5,1.2,0.8,1.7,1.2
			l0.1,0.1c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.2,1,0.8,0.8,1.5c-0.1,0.6-0.7,1-1.7,1.3c-1.9,0.5-4.3-0.4-5.4-1l-3.7,6.6
			c0.6,0.5,2.5,1.9,5.9,2c4.5,0.1,8.3-1.6,10.4-4.7c2.4-3.4,2.2-6.1,1.9-7.8c-0.4-2-2.1-3.5-3.1-4l-0.2-0.1
			c-1.2-0.6-3.1-1.5-2.7-2.8c0.1-0.3,0.5-0.6,1-0.8c1.6-0.5,4.5-0.3,7.1,1.2L33.9,2.5z"
      />
      <path d="M65.8,0.3l-18,27.1h8l2.4-4.2h6.6l-0.4,4.2h6.8L73,0.3H65.8z M65.1,18.7h-4.3l5.2-9L65.1,18.7z" />
      <polygon points="37.9,21 47.2,0.4 39.8,0.4 27.3,27.6 45.7,27.6 48.7,21 		" />
      <path
        d="M95.9,16.6c3.8-4.2,4.2-9.5,3.5-11.9c-0.5-2.1-2.8-4.4-7.6-4.7c-4.1-0.2-5.8,0.3-6.3,0.5l-12.4,27h7.7
			l3.2-6.8l0.3,0C84.3,20.8,92,21,95.9,16.6z M91,11.5c-1.7,3-3.9,3-3.9,3l3.5-7.9C92.2,6.6,92.7,8.4,91,11.5z"
      />
    </g>
    <g>
      <polygon points="41.1,37.3 44.6,30 26.9,30 23.6,37.3 28.5,37.3 19.4,57.5 27.5,57.5 36.7,37.3 		" />
      <polygon points="46.6,30.2 33.9,57.3 41.6,57.3 54.5,30.2 		" />
      <path
        d="M68.4,30.3c-0.6-0.1-1.2-0.2-2-0.2c-4.2,0-10.2,2.5-13.6,10.2c-2.7,6.2-3.5,11.7-2,14.8
			c0.6,1.2,1.5,2.1,2.7,2.4c0.1,0,6,1.6,10.5-2.6c3.5-3.3,5.1-6.2,5.6-7.4h-8.4c-0.4,0.7-1.5,2.7-1.9,3.2c-0.4,0.6-0.8,0.9-1.2,0.9
			c0,0,0,0,0,0c-0.2,0-0.5-0.1-0.8-0.4c-0.3-0.5-0.3-1.6,0.2-2.9c0.5-1.4,2.7-6.8,2.8-7.1c0.3-0.7,1.9-4.2,3.1-4.9
			c0.3-0.2,0.7-0.3,1,0c0.4,0.3,0.6,1-0.4,3.9h8c0.2-1.2,0.9-5.2-0.7-7.8C70.6,31.3,69.6,30.6,68.4,30.3z"
      />
      <path
        d="M23.6,32.1c-1.2-0.7-5.3-3-10.4-2.2c-5.7,0.8-8.5,5-9,7.6c-0.4,2.1-0.5,5.1,3.2,7.5c0.7,0.5,1.2,0.8,1.7,1.2
			l0.1,0.1c0.3,0.2,0.6,0.4,0.9,0.7c0.2,0.2,1,0.8,0.8,1.5c-0.1,0.6-0.7,1-1.7,1.3c-1.9,0.5-4.3-0.4-5.4-1L0,55.3
			c0.6,0.5,2.5,1.9,5.9,2c4.5,0.1,8.4-1.6,10.4-4.7c2.4-3.4,2.2-6.1,1.9-7.8c-0.4-2-2.1-3.5-3.1-4l-0.2-0.1
			c-1.2-0.6-3.1-1.5-2.7-2.8c0.1-0.3,0.5-0.6,1-0.8c1.6-0.5,4.5-0.3,7.1,1.2L23.6,32.1z"
      />
      <polygon
        points="89.2,41.7 99.6,30.3 92.5,30.3 84.1,39.2 88.2,30.3 80.5,30.3 68.2,57.3 75.7,57.3 80.1,48 
			82.1,45.7 87.2,57.3 93.9,57.3 87.7,43.4 		"
      />
    </g>
  </SlapstickLogoSVG>
)

const SlapstickIconSVG = styled('svg')`
  fill: white;
  width: 3em;

  &:hover {
    fill: ${p => p.theme.yellow};
  }
`

export const SlapstickIcon = () => (
  <SlapstickIconSVG x="0px" y="0px" viewBox="0 0 250 250">
    <g>
      <path
        d="M194,7c27,0,49,22,49,49v138c0,27-22,49-49,49H91.7L7,158.3V56C7,29,29,7,56,7H194 M194,0H56
      C25.1,0,0,25.1,0,56v105.2L88.8,250H194c30.9,0,56-25.1,56-56V56C250,25.1,224.9,0,194,0L194,0z"
      />
    </g>
    <g>
      <g>
        <g>
          <path
            d="M124.6,161c-15.4,0-29.8-5.9-40.7-16.7c-10.1-10-15.9-23.2-16.8-37.3h3.8c0.8,13.6,6.3,25.9,15.7,35.1
          c10,9.8,23.5,15.2,38,15.2s28-5.4,38-15.2c9.4-9.2,14.9-21.5,15.7-35.1h3.8c-0.8,14.2-6.7,27.4-16.8,37.3
          C154.4,155.1,140,161,124.6,161z"
          />
          <path
            d="M185.6,103.4h-10.8c0,29.3-22.6,50.3-50.3,50.3s-50.3-21-50.3-50.3H63.5c0,34.2,27.4,61,61,61
          S185.6,137.6,185.6,103.4L185.6,103.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <ellipse
            transform="matrix(0.5439 -0.8392 0.8392 0.5439 -45.8955 144.6283)"
            cx="110.1"
            cy="114.5"
            rx="14.4"
            ry="9.2"
          />
        </g>
      </g>
      <g>
        <g>
          <ellipse
            transform="matrix(0.5439 -0.8392 0.8392 0.5439 -31.7958 170.5682)"
            cx="141"
            cy="114.5"
            rx="14.4"
            ry="9.2"
          />
        </g>
      </g>
    </g>
    <g>
      <path
        d="M32.8,168.2c27,0,49,22,49,49v15.9l-64.9-64.9H32.8 M32.8,161.2H0L88.8,250v-32.8
      C88.8,186.3,63.7,161.2,32.8,161.2L32.8,161.2z"
      />
    </g>
  </SlapstickIconSVG>
)

const InstagramSVG = styled('svg')`
  fill: white;

  height: 1.5em;

  &:hover {
    fill: ${p => p.theme.highlight};
  }
`

export const InstagramLogo = () => (
  <InstagramSVG x="0px" y="0px" viewBox="0 0 28 28">
    <g>
      <path
        d="M0,19.8c0-3.9,0-7.8,0-11.7c0-0.2,0.1-0.4,0.1-0.7c0.3-3.9,2.5-6.4,6.3-7.2C7,0.2,7.6,0.1,8.2,0
		c3.9,0,7.8,0,11.7,0c0.2,0,0.4,0.1,0.7,0.1c3.9,0.3,6.4,2.5,7.2,6.3C27.8,7,27.9,7.6,28,8.2c0,3.9,0,7.8,0,11.7
		c0,0.2-0.1,0.4-0.1,0.7c-0.3,3.9-2.5,6.4-6.3,7.2c-0.6,0.1-1.2,0.2-1.8,0.3c-3.9,0-7.8,0-11.7,0c-0.2,0-0.4-0.1-0.7-0.1
		c-3.9-0.3-6.4-2.5-7.2-6.3C0.2,21,0.1,20.4,0,19.8z M25.3,14C25.4,14,25.4,14,25.3,14c0.1-1.7,0.1-3.5,0.1-5.2
		c0-0.4-0.1-0.7-0.1-1.1c-0.3-3-2-4.8-5-4.9c-4.2-0.1-8.5-0.1-12.7,0c-3,0.1-4.8,2-4.9,4.9c-0.1,4.2-0.1,8.5,0,12.7c0.1,3,2,4.9,5,5
		c4.2,0.1,8.4,0.1,12.5,0c3.1-0.1,4.9-1.9,5.1-5C25.4,18.2,25.3,16.1,25.3,14z"
      />
      <path
        d="M14,21.2c-4,0-7.2-3.3-7.2-7.3c0-4,3.3-7.2,7.2-7.2c4,0,7.2,3.3,7.2,7.3C21.2,18,17.9,21.2,14,21.2z M9.4,14
		c0,2.5,2.1,4.7,4.6,4.7c2.6,0,4.7-2,4.7-4.6c0-2.6-2-4.7-4.6-4.7C11.5,9.3,9.4,11.4,9.4,14z"
      />
      <path d="M21.5,8.2c-1,0-1.7-0.7-1.7-1.7c0-1,0.7-1.7,1.7-1.7c1,0,1.7,0.7,1.7,1.7C23.1,7.5,22.5,8.2,21.5,8.2z" />
    </g>
  </InstagramSVG>
)

const CloseIconSVG = styled('svg')`
  fill: white;
  height: 1.5em;

  &:hover {
    fill: ${p => p.theme.highlight};
    cursor: pointer;
  }
`

export const CloseIcon = () => (
  <CloseIconSVG x="0px" y="0px" viewBox="0 0 35 35">
    <g>
      <rect
        x="-5.1"
        y="15.8"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 18.2678 -7.5668)"
        width="46.7"
        height="5"
      />
    </g>
    <g>
      <rect
        x="-5.1"
        y="15.8"
        transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 44.1023 18.2678)"
        width="46.7"
        height="5"
      />
    </g>
  </CloseIconSVG>
)

const HamburgerIconSVG = styled('svg')`
  fill: white;
  height: 1.5em;

  &:hover {
    fill: ${p => p.theme.highlight};
    cursor: pointer;
  }
`

export const HamburgerIcon = () => (
  <HamburgerIconSVG x="0px" y="0px" viewBox="0 0 35 35">
    <g>
      <rect x="0" width="35" height="5" />
      <rect y="15" width="35" height="5" />
      <rect y="30" width="35" height="5" />
    </g>
  </HamburgerIconSVG>
)
