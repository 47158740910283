import { injectGlobal } from 'react-emotion'
import { device } from './utils'
import emotionNormalize from 'emotion-normalize'
import 'typeface-roboto'

export const theme = {
  fg: 'white',
  bg: 'black',
  highlight: '#ffec00',
  blue: '#178ccf',
  yellow: '#ffec00',
  purple: '#b284fe',
  green: '#3a9e96',
  salmon: '#f98470',
}

injectGlobal`
  ${emotionNormalize}
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont;
    background: ${theme.bg};
    color: ${theme.fg};
    font-size: 16px;

    @media ${device.mobileL} {
      font-size: 18px;
    }
  }

  button, input, optgroup, select, textarea {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont;
  }

  h1 {
    font-size: 3.5em;
    margin: 0.4em 0;
  }

  h2 {
    margin-top: 2em;
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  h3 {
    margin-top: 2em;
    margin-bottom: .5em;
  }

  p, ol, ul {
    margin-bottom: 1em;
    line-height: 1.4;
  }

  ol, ul {
    margin-left: 1em;
  }


  li {
    margin-left: 0;
    padding-left: .5em;
    margin-bottom: .5em;
  }

  a {
    color: #fa8471;
    text-decoration: none;
  }
`
