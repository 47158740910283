import React from 'react'
import styled from 'react-emotion'
import { Portal } from 'react-portal'

const ModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`

export class Modal extends React.Component {
  state = { active: false }

  static getDerivedStateFromProps({ children }) {
    if (children) {
      return { active: true, children }
    } else {
      return { active: false }
    }
  }

  render() {
    if (this.state.active)
      return (
        <Portal>
          <ModalContainer>{this.props.children}</ModalContainer>
        </Portal>
      )
    return null
  }
}
