module.exports = {
  siteMetadata: {
    title: 'Slapstick',
    downloadUrlIos:
      'https://itunes.apple.com/us/app/slapstick-app/id1380849268?ls=1&mt=8',
    downloadUrlAndroid:
      'https://play.google.com/store/apps/details?id=tv.buck.slapstick.android',
    instagramUrl: 'https://www.instagram.com/slapstick.app',
    instagramHashtagUrl: 'https://www.instagram.com/explore/tags/slapstickapp/',
    builtByUrl: 'http://www.buck.co/',
    videoUrl: 'https://www.youtube.com/embed/yQsEe1s1Mrs',
    contactUrl: 'mailto:hello@slapstick.app?subject=Got a question',
    newStickerPackContactUrl:
      'mailto:hello@slapstick.app?subject=New Sticker Pack Idea',
    letsCollaborateContactUrl:
      'mailto:hello@slapstick.app?subject=Lets Collaborate',
    pressKitUrl:
      'https://firebasestorage.googleapis.com/v0/b/slapstick-app.appspot.com/o/public%2Fslapstick-press-kit.zip?alt=media&token=93bcb5fa-5101-40a8-9900-caae404b9663',
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-emotion`,
      options: {
        hoist: true,
        sourceMap: true,
        autoLabel: true,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/assets/`,
        name: 'assets',
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-mailchimp`,
      options: {
        endpoint: `https://buck.us18.list-manage.com/subscribe/post?u=0120a05986022e669de13f3b5&amp;id=068d9e725e`,
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: 'UA-122593218-1',
        // head: false,
        // anonymize: true,
        // Respect do not track
        // respectDNT: true,
        // Avoids sending pageview hits from custom paths
        // exclude: ['/preview/**', '/do-not-track/me/too/'],
      },
    },

    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Slapstick',
        short_name: 'Slapstick',
        start_url: '/',
        background_color: '#000000',
        theme_color: '#a2466c',
        display: 'minimal-ui',
        icon: 'static/logo.png', // This path is relative to the root of the site.
      },
    },
    'gatsby-plugin-offline',
  ],
}
