import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'react-emotion'
import { ThemeProvider } from 'emotion-theming'
import { Header, Footer } from './'
import { theme } from '../theme'

const Container = styled('div')`
  min-width: 100px;
`

const Content = styled('div')`
  display: 'flex';
  flex-direction: column;
  background: ${p => p.color};
  color: ${p => p.theme.bg};
`

export default class Layout extends React.Component {
  state = {
    mobileMenuActive: false,
  }

  onMobileMenuToggle = () => {
    this.setState({
      mobileMenuActive: !this.state.mobileMenuActive,
    })
  }

  render() {
    const {
      children,
      backgroundColor,
      pageTitle,
      pageDescription = 'Stick it to boring',
    } = this.props
    const { mobileMenuActive } = this.state

    return (
      <StaticQuery
        query={graphql`
          query SiteQuery {
            site {
              buildTime
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => {
          let title = data.site.siteMetadata.title
          if (pageTitle && pageTitle != '') title = `${title} | ${pageTitle}`
          return (
            <>
              <Helmet title={title} htmlAttributes={{ lang: 'en' }}>
                {pageDescription && (
                  <meta name="description" content={pageDescription} />
                )}
                <meta
                  name="build-time"
                  content={new Date(data.site.buildTime)}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                  rel="mask-icon"
                  href="/safari-pinned-tab.svg"
                  color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#00aba9" />
                <meta name="theme-color" content="#ffffff" />
              </Helmet>
              <ThemeProvider theme={theme}>
                <Container>
                  <Header
                    mobileMenuActive={mobileMenuActive}
                    onMobileMenuToggle={this.onMobileMenuToggle}
                  />

                  <Content color={backgroundColor}>{children}</Content>

                  <Footer />
                </Container>
              </ThemeProvider>
            </>
          )
        }}
      />
    )
  }
}
