import React from 'react'
import styled, { css } from 'react-emotion'
import * as gatsby from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const link = p => css`
  display: inline-block;
  text-decoration: none;
  color: ${p.theme.fg};
  cursor: pointer;

  &:hover {
    color: ${p.theme.highlight};
  }

  &.active {
    color: ${p.theme.highlight};
  }
`

const LinkInner = styled(gatsby.Link)`
  ${link};
`

export const Link = props => {
  return (
    <LinkInner
      isActive={params => params && params.isExact}
      activeClassName="active"
      {...props}
    />
  )
}

const ExternalLinkContainer = styled(OutboundLink)`
  ${link};
`

export const ExternalLink = props => (
  <ExternalLinkContainer target="_blank" rel="noopener" {...props} />
)
