import styled, { css } from 'react-emotion'
import { blacklistProps } from '../utils'

const _Text = blacklistProps(
  ['center', 'wrap', 'bold', 'uppercase', 'large', 'huge', 'small'],
  styled('div')`
  white-space: nowrap;
  line-height: 1;
  /* transition: 0.2s ease;
  transition-property: font-size; */

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}

  ${props =>
    props.wrap &&
    css`
      white-space: inherit;
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${props =>
    props.large &&
    css`
      font-size: 1.5em;
    `}

   ${props =>
     props.huge &&
     css`
       font-size: 3em;
     `}

  ${props =>
    props.small &&
    css`
      font-size: 0.8em;
    `}
`
)

export const Text = blacklistProps(
  ['center', 'wrap', 'bold', 'uppercase', 'large', 'huge', 'small'],
  _Text
)

export const CenterTitle = styled('h2')`
  margin-top: 0;
  font-size: 2em;
  text-align: center;
  display: block;
`
