import React from 'react'
import styled from 'react-emotion'
import { ExternalLink, AppBadgeIos, AppBadgeAndroid } from '../components'
import { device, center } from '../utils'
import { StaticQuery, graphql } from 'gatsby'

const BodyContainer = styled('div')`
  ${center};
  padding-top: 4em;
  padding-bottom: 4em;
  color: ${p => p.theme.fg};

  > *:not(:last-child) {
    margin-bottom: 2em;
  }

  @media ${device.mobileL} {
    flex-direction: row;
    justify-content: space-between;
    align-items: initial;
  }

  @media ${device.tablet} {
    text-align: inherit;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    grid-column-gap: 4em;

    > *:not(:last-child) {
      margin-bottom: inherit;
    }
  }
`

const SlapstickLogo = styled('div')`
  background: url(/slapstick_logo.svg) no-repeat;
  background-size: contain;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  overflow: hidden;
  margin-left: -6px;

  &:hover {
    animation: shake 0.75s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @media ${device.tablet} {
    width: 125px;
    height: 125px;
  }

  @keyframes shake {
    10%,
    90% {
      transform: rotate(0);
    }

    20%,
    80% {
      transform: rotate(5deg);
    }

    30%,
    50%,
    70% {
      transform: rotate(-10deg);
    }

    40%,
    60% {
      transform: rotate(30deg);
    }
  }
`

const Title = styled('h1')`
  font-size: 3em;
  margin: 0;
  margin: 0.3em 0;
`

const Body = styled('div')`
  font-size: 0.95em;
  margin-bottom: 2em;
`

const Phone = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 40px;
  border: 4px solid yellow;
  margin-right: auto;
  margin-left: auto;
  width: 225px;
  height: 450px;

  @media ${device.tablet} {
    width: 300px;
    height: 600px;
  }
`

const PhoneScreen = styled('div')`
  width: 90%;
  height: 80%;
`

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-right: inherit;
  }

  @media ${device.tablet} {
    display: block;
    justify-content: inherit;
    align-items: inherit;
  }
`

const PhoneScreenVideo = styled('video')`
  width: 100%;
  height: 100%;
`

const ContentColumn = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    align-items: inherit;
  }
`

const IntroHeaderText = styled('div')`
  font-size: 3.5em;
  margin: 0.4em 0;
  font-weight: 700;
`

const BadgeContainer = styled('a')`
  margin-bottom: 1em;
`

export const Intro = () => (
  <StaticQuery
    query={graphql`
      query IntroQuery {
        site {
          siteMetadata {
            title
            downloadUrlIos
            downloadUrlAndroid
            instagramHashtagUrl
          }
        }
      }
    `}
    render={({ site }) => (
      <BodyContainer>
        <ContentColumn>
          <HeaderContainer>
            <ExternalLink
              href={site.siteMetadata.downloadUrlIos}
              aria-label={`${site.siteMetadata.title} iOS App`}
            >
              <SlapstickLogo />
            </ExternalLink>
            <IntroHeaderText>Slapstick</IntroHeaderText>
          </HeaderContainer>
          <Body>
            <p>
              Slapstick is a freshly-revamped Augmented Reality app that lets
              you ‘slap’ animated stickers onto the world around you and add a
              little edge to your social posts. With post-capture editing (a
              mobile AR first!) and themes ranging from “House Party” to the
              NSFW “Kinky,” there’s no shortage of ways you can stick it to
              boring. Best of all, it’s free, with new stickers announced on
              Instagram. So go ahead—slap that App Store button and go stick it.
            </p>

            <p>
              And if you enjoy getting slap-happy, please help us out! Tag your
              social media slaps with{' '}
              <ExternalLink
                href={site.siteMetadata.instagramHashtagUrl}
                aria-label="#slapstickapp on Instagram"
              >
                #slapstickapp
              </ExternalLink>{' '}
              so we can spread the sticky goodness.
            </p>
          </Body>
          <BadgeContainer href={site.siteMetadata.downloadUrlAndroid}>
            <AppBadgeAndroid />
          </BadgeContainer>
          <BadgeContainer href={site.siteMetadata.downloadUrlIos}>
            <AppBadgeIos />
          </BadgeContainer>
        </ContentColumn>
        <div>
          <Phone>
            <PhoneScreen>
              <PhoneScreenVideo
                autoPlay
                loop
                playsInline
                muted
                poster="/videos/phone-screen.jpg"
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/slapstick-app.appspot.com/o/public%2Fphone-screen.mp4?alt=media&token=c70dab4d-80f6-4927-86d6-42d7d415e8a5"
                  type="video/mp4"
                />
              </PhoneScreenVideo>
            </PhoneScreen>
          </Phone>
        </div>
      </BodyContainer>
    )}
  />
)
