import styled from 'react-emotion'

export const Button = styled('button')`
  background: ${p => p.theme.bg};
  color: ${p => p.theme.fg};
  padding: 1em;
  font-weight: 900;
  border: none;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.highlight};
    /* background: ${p => p.theme.highlight}; */
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255,255,255,.2)
  }
`
