import { css } from 'react-emotion'

export const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '740px',
  tablet: '800px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
  desktopL: `(min-width: ${sizes.desktop})`,
}

export const padding = css`
  padding-left: 2em;
  padding-right: 2em;

  @media ${device.tablet} {
    padding-left: 2em;
    padding-right: 2em;
  }
`

export const center = css`
  transition: 0.2s ease;
  transition-property: height, width;
  padding-left: 2em;
  padding-right: 2em;

  @media ${device.mobileM} {
    margin-right: auto;
    margin-left: auto;
  }

  @media ${device.tablet} {
    margin-right: auto;
    margin-left: auto;
    width: 800px;
    padding-left: 2em;
    padding-right: 2em;
  }

  @media ${device.laptopL} {
    width: 1000px;
  }
`

export const blacklistProps = (list, Component) => {
  if (!Component.defaultProps) Component.defaultProps = {}
  if (Component.defaultProps.blacklist) {
    Component.defaultProps.blacklist = [
      ...Component.defaultProps.blacklist,
      ...list,
    ]
  } else {
    Component.defaultProps.blacklist = list
  }
  return Component
}
