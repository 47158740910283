import styled from 'react-emotion'
import { center } from '../utils'

export const Container = styled('div')`
  padding: 3em 0;
  ${center};
`

export const ContainerFlex = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3em 0;
  ${center};
`
