import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styled, { cx } from 'react-emotion'
import { device, center, sizes } from '../utils'
import ReactPlayer from 'react-player'

const VideoContainer = styled('div')`
  width: 100%;

  @media ${device.laptopL} {
    background: rgb(255, 228, 0);
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const VideoBorder = styled('div')`
  position: relative;
  border: 10px solid ${p => p.theme.yellow};
  box-sizing: border-box;

  transition: 0.2s ease;
  transition-property: border;

  @media ${device.tablet} {
    border: 2em solid ${p => p.theme.yellow};
  }
`

const VideoThumbnail = styled('img')`
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 999;
  transition: 0.25s ease;
  transition-delay: 0.5s;
  transition-property: opacity;
  pointer-events: none;

  &.hidden {
    opacity: 0;
    animation: none;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const InnerContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100;

  width: 100%;
  margin-right: auto;
  margin-left: auto;
  transition: 0.2s ease;
  transition-property: margin, padding;

  max-width: ${sizes.laptopL};
`

class VideoPlayer extends React.Component {
  state = {
    isReady: false,
  }

  onReady = () => this.setState({ isReady: true })

  render() {
    const { url, thumbnailSrcSet } = this.props
    const { isReady } = this.state

    return (
      <InnerContainer>
        <VideoThumbnail
          srcSet={thumbnailSrcSet}
          className={cx({ hidden: isReady })}
        />
        <StyledReactPlayer
          onReady={this.onReady}
          url={url}
          width="100%"
          height="100%"
          controls={true}
          hide={isReady ? undefined : 'true'}
        />
      </InnerContainer>
    )
  }
}

export const Video = props => (
  <StaticQuery
    query={graphql`
      query VideoQuery {
        site {
          siteMetadata {
            videoUrl
          }
        }
        thumbnail: file(relativePath: { eq: "video-thumbnail.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              srcSet
            }
          }
        }
      }
    `}
    render={({ site, thumbnail }) => (
      <VideoContainer>
        <VideoPlayer
          url={site.siteMetadata.videoUrl}
          thumbnailSrcSet={thumbnail.childImageSharp.fluid.srcSet}
        />
      </VideoContainer>
    )}
  />
)
