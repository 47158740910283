import React from 'react'
import styled from 'react-emotion'
import { device, center } from '../utils'
import {
  Link,
  ExternalLink,
  Modal,
  Text,
  Branding,
  SlapstickLogo,
  HamburgerIcon,
  CloseIcon,
  InstagramLogo,
  AppBadgeIos,
  AppBadgeAndroid,
} from './'
import { StaticQuery, graphql } from 'gatsby'

const MainMenuContainer = styled('div')`
  display: none;
  height: 5em;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1em;

  ${center};

  @media ${device.mobileL} {
    display: flex;
  }
`

const MainMenu = ({ site }) => (
  <MainMenuContainer>
    <Link to="/" aria-label={`${site.siteMetadata.title} Homepage`}>
      <SlapstickLogo />
    </Link>
    {/* <ExternalLink href={site.siteMetadata.downloadUrlIos}>
      <Text uppercase>Download</Text>
    </ExternalLink> */}
    {/* <ExternalLink
      href={site.siteMetadata.pressKitUrl}
      aria-label="Press Kit Download"
    >
      <Text uppercase>Press Kit</Text>
    </ExternalLink> */}
    <Link to="/faq" aria-label="Frequently Asked Questions">
      <Text uppercase>FAQ</Text>
    </Link>
    <Link to="/contact" aria-label="Contact">
      <Text uppercase>Contact</Text>
    </Link>
    <ExternalLink
      href={site.siteMetadata.instagramHashtagUrl}
      aria-label="#SlapstickApp on Instagram"
    >
      <Text uppercase>#SlapstickApp</Text>
    </ExternalLink>
    <ExternalLink
      href={site.siteMetadata.downloadUrlAndroid}
      aria-label="Download Slapstick for Android"
    >
      <AppBadgeAndroid />
    </ExternalLink>
    <ExternalLink
      href={site.siteMetadata.downloadUrlIos}
      aria-label="Download Slapstick for iOS"
    >
      <AppBadgeIos />
    </ExternalLink>
  </MainMenuContainer>
)

const MobileMenuButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.yellow};
  width: calc(100vw - 10em);
  height: 3em;
  color: black;
  text-transform: uppercase;
  font-weight: 900;

  &:hover {
    background: white;
    color: black;
  }
`

const MenuToggle = ({ mobileMenuActive = false, ...props }) => {
  if (mobileMenuActive) {
    return <CloseIcon {...props} />
  } else {
    return <HamburgerIcon {...props} />
  }
}

const MobileMenuHeaderContainer = styled('div')`
  display: flex;
  height: 5em;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2em;
  flex-shrink: 0;

  @media ${device.mobileL} {
    display: none;
  }
`

const MobileMenuHeader = ({ mobileMenuActive, onMobileMenuToggle, site }) => (
  <MobileMenuHeaderContainer>
    <div onClick={onMobileMenuToggle}>
      <MenuToggle mobileMenuActive={mobileMenuActive} />
    </div>
    <Link to="/">
      <SlapstickLogo />
    </Link>
    <div onClick={onMobileMenuToggle}>
      <ExternalLink
        href={site.siteMetadata.instagramUrl}
        aria-label="Slapstick Instagram"
      >
        <InstagramLogo />
      </ExternalLink>
    </div>
  </MobileMenuHeaderContainer>
)

const MobileMenuContainer = styled('div')`
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${p => p.theme.bg};

  @media ${device.mobileL} {
    display: none;
  }
`

const MobileMenuContentContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    flex-shrink: 0;

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  @media screen and (min-height: 700px) {
  }
`

const MobileMenu = ({ mobileMenuActive, onMobileMenuToggle, site }) => (
  <MobileMenuContainer>
    <MobileMenuHeader
      site={site}
      mobileMenuActive={mobileMenuActive}
      onMobileMenuToggle={onMobileMenuToggle}
    />
    <MobileMenuContentContainer>
      <div>
        <Link
          to="/faq"
          onClick={onMobileMenuToggle}
          aria-label="Frequently Asked Questions"
        >
          <MobileMenuButton uppercase>FAQ</MobileMenuButton>
        </Link>
        <Link
          to="/contact"
          aria-label="Contact support"
          onClick={onMobileMenuToggle}
        >
          <MobileMenuButton uppercase>Contact</MobileMenuButton>
        </Link>
        <ExternalLink
          href={site.siteMetadata.instagramHashtagUrl}
          aria-label="#SlapstickApp on Instagram"
        >
          <MobileMenuButton uppercase>#SlapstickApp</MobileMenuButton>
        </ExternalLink>
      </div>

      <div>
        <ExternalLink
          href={site.siteMetadata.downloadUrlAndroid}
          aria-label="Download Slapstick for Android"
        >
          <AppBadgeAndroid />
        </ExternalLink>
        <ExternalLink
          href={site.siteMetadata.downloadUrlIos}
          aria-label="Download Slapstick for iOS"
        >
          <AppBadgeIos />
        </ExternalLink>
      </div>

      <div>
        <Branding />
      </div>
    </MobileMenuContentContainer>
  </MobileMenuContainer>
)

export const Header = ({ mobileMenuActive = false, onMobileMenuToggle }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            title
            pressKitUrl
            instagramHashtagUrl
            downloadUrlIos
            downloadUrlAndroid
            instagramUrl
            builtByUrl
            videoUrl
            contactUrl
          }
        }
      }
    `}
    render={({ site }) => {
      if (mobileMenuActive) {
        return (
          <Modal>
            <MobileMenu
              site={site}
              mobileMenuActive={mobileMenuActive}
              onMobileMenuToggle={onMobileMenuToggle}
            />
          </Modal>
        )
      } else {
        return (
          <>
            <MainMenu site={site} />
            <MobileMenuHeader
              site={site}
              mobileMenuActive={mobileMenuActive}
              onMobileMenuToggle={onMobileMenuToggle}
            />
          </>
        )
      }
    }}
  />
)

const BuckLogoSVG = styled('svg')`
  margin-top: 0.4em;
  width: 70px;
  height: 26px;
  stroke: none;
  fill: white;

  &:hover {
    fill: yellow;
  }
`

export const BuckLogo = () => (
  <BuckLogoSVG
    width="166"
    height="40"
    viewBox="0 0 166 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 10.54C40 4.72482 35.2752 0 29.4704 0H1.06957C0.477674 0 0 0.477674 0 1.06957V38.9304C0 39.5223 0.477674 40 1.06957 40C1.07996 40 1.07996 40 1.09034 40H29.46C35.2752 40 40 35.2752 40 29.46C40 25.3063 37.5909 21.7134 34.0914 20C37.5909 18.2866 40 14.6833 40 10.54ZM2.14953 2.14953H23.1049C20.5711 4.07061 18.9304 7.12357 18.9304 10.54C18.9304 13.9564 20.5711 17.0093 23.1049 18.9304H2.14953V2.14953ZM37.8609 29.4704C37.8609 34.0914 34.1018 37.8505 29.4912 37.8609H2.14953V21.08H29.4704C34.0914 21.08 37.8609 24.839 37.8609 29.4704ZM29.4704 18.9304C24.839 18.9304 21.08 15.1713 21.08 10.54C21.08 5.90862 24.839 2.14953 29.4704 2.14953C34.1018 2.14953 37.8609 5.90862 37.8609 10.54C37.8609 15.1713 34.1018 18.9304 29.4704 18.9304Z"
      fill="white"
    />
    <path
      d="M80.9572 0H44.0428C43.4657 0 43 0.477674 43 1.06957V20C43 31.028 51.7477 40 62.5 40C73.2422 40 81.9899 31.0384 82 20.0208C82 20.0104 82 20.0104 82 20V1.06957C82 0.477674 81.5343 0 80.9572 0ZM61.4572 37.8297C52.345 37.269 45.0958 29.4912 45.0958 20V2.14953H61.4572V37.8297V37.8297ZM79.9143 20C79.9143 29.4912 72.6651 37.269 63.553 37.8297V2.14953H79.9143V20Z"
      fill="white"
    />
    <path
      d="M121.967 0H103.51C92.7577 0 84 8.97196 84 20C84 31.028 92.7476 40 103.5 40H121.957C122.534 40 123 39.5223 123 38.9304C123 30.1662 117.472 22.6999 109.808 20C117.472 17.3105 123 9.83385 123 1.06957C123.01 0.477674 122.544 0 121.967 0ZM120.884 37.8609H103.51C94.2562 37.8609 86.6729 30.4258 86.1262 21.08H103.51C112.754 21.08 120.347 28.5151 120.884 37.8609ZM103.51 18.9304H86.1262C86.6729 9.58463 94.2562 2.14953 103.51 2.14953H120.894C120.347 11.4953 112.754 18.9304 103.51 18.9304Z"
      fill="white"
    />
    <path
      d="M147.514 20L165.684 1.82762C166.359 1.15265 165.881 0 164.926 0H127.069C126.478 0 126 0.477674 126 1.06957V38.9304C126 39.5223 126.478 40 127.069 40H164.895C165.85 40 166.328 38.8474 165.653 38.1724L147.514 20ZM162.33 2.14953L145.987 18.4839L129.655 2.14953H162.33ZM128.139 3.66563L144.482 20.0104L128.139 36.3448V3.66563ZM129.655 37.8609L145.998 21.5161L162.341 37.8609H129.655Z"
      fill="white"
    />
  </BuckLogoSVG>
)
